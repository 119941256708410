import { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { mask, unMask } from 'remask';
import { ImUserPlus } from 'react-icons/im';
import {
  Container,
  ContainerButtonBack,
  ContainerCreateAccount,
  ContainerSignIn,
  ContainerTitleCreate,
  DescriptionSignIn,
  DescriptonLink,
  TitleCreate,
} from './styles';
import { useTab } from '../../hooks/tabIndex';
import { IdEmpresa } from '../../services/api';
import { BackButton } from '../../components/ButtonBack';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { notification } from '../../utils/notifications';
import { useTheme } from 'styled-components';
import { useAuth } from '../../hooks/auth';
import { FBPixelTrackEvent } from '../../events/FaceBookPixelEvents';
import { Spacer } from '../../components/Spacer';
import * as yup from 'yup';
import { validarCPF } from 'utils/validateCpf';

const intialFormState = {
  name: '',
  phone: '',
  document: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const CreateAccount = () => {
  const [form, setForm] = useState(intialFormState);
  const { setTab } = useTab();
  const { signUp } = useAuth();
  const { colors } = useTheme();

  const validateFields = async () => {
    try {
      const formSchema = yup.object().shape({
        name: yup.string().required('Preencha o campo nome'),
        phone: yup.string().required('Preencha o campo telefone'),
        document: yup.string()
          .test('test-valid-cpf', 'CPF inválido', (cpf) => validarCPF(cpf)),
        email: yup
          .string()
          .email('Insira um email valido')
          .required('Preencha o campo email'),
        password: yup.string().required('Preencdha o campo senha'),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password')], 'As senhas precisam ser iguais')
          .required('Preencha o campo confirmação de senha'),
      });

      const validated = await formSchema.validate(form);

      return validated;
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        notification('error', error.message);
        console.log(error);
      }
      return;
    }
  };

  const clearFields = () => {
    setForm(intialFormState);
  };

  const handleSignUp: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();

    const validatedData = await validateFields();
    if (!validatedData) return;

    const { name, phone, email, password, document } = validatedData;

    const response = await signUp({
      companyId: IdEmpresa,
      name,
      phone,
      email,
      document,
      password,
    });

    if (response) {
      clearFields();
      FBPixelTrackEvent.completeRegistration();

      setTimeout(() => {
        setTab(4);
      }, 1300);
    }
  };

  const onChangeForm = (label: keyof typeof form) => {
    return (event: string | { target: { value: string } }) => {
      const value = typeof event === 'string' ? event : event?.target?.value;
      setForm((state) => ({ ...state, [label]: value }));
    };
  };

  const onChangePhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeForm('phone')(mask(unMask(e.target.value), ['(99)99999-9999']));
  };

  return (
    <Container>
      <ContainerButtonBack>
        <BackButton onClick={() => setTab(4)} />
      </ContainerButtonBack>
      <ContainerCreateAccount>
        <ContainerTitleCreate>
          <ImUserPlus color={colors.main} size={40} />
          <TitleCreate>Faça seu cadastro</TitleCreate>
        </ContainerTitleCreate>
        <Input
          placeholder="Nome"
          value={form.name}
          onChange={onChangeForm('name')}
        />
        <Input
          placeholder="Celular: (xx) 9xxxx-xxxx"
          value={form.phone}
          onChange={onChangePhone}
        />
        <Input
          placeholder="CPF: xxx.xxx.xxx-xx"
          value={form.document}
          maxLength={11}
          onChange={onChangeForm('document')}
        />
        <Input
          placeholder="E-mail"
          value={form.email}
          onChange={onChangeForm('email')}
        />
        <Input
          placeholder="Senha"
          type="password"
          value={form.password}
          onChange={onChangeForm('password')}
        />
        <Input
          placeholder="Confirmação de senha"
          type="password"
          value={form.confirmPassword}
          onChange={onChangeForm('confirmPassword')}
        />
        <Spacer y={24} />

        <Button onClick={handleSignUp}>Cadastrar</Button>
        <ContainerSignIn>
          <DescriptionSignIn>
            Caso já tenha cadastro clique{' '}
            <DescriptonLink onClick={() => setTab(4)}>aqui</DescriptonLink>
          </DescriptionSignIn>
        </ContainerSignIn>
      </ContainerCreateAccount>
    </Container>
  );
};

export default CreateAccount;
