import QRCode from 'react-qr-code';
import * as S from './styles';
import { BackButton } from '../ButtonBack';
import { money } from 'utils/money';
import { Text } from '../Text/Text';
import pixLogo from '../../assets/logo_pix.png';
import Button from '../Button';
import { PixPayment } from 'entities/pixPayment.entity';
import { useCallback, useEffect, useState } from 'react';
import { notifications } from 'utils/notifications';
import { openSocket } from 'utils/openSocket';
import { FaCheckCircle } from "react-icons/fa";
import { Order } from 'entities/Order.entity';

export interface PaymentModalProps {
  payment: PixPayment;
  onClose: () => void;
  amount?: number;
  deliveryId?: string | null;
  setOrder?: React.Dispatch<React.SetStateAction<Order | null>> | null;
}

const PaymentModal = (props: PaymentModalProps) => {
  const { payment, onClose, amount, deliveryId, setOrder } = props;
  const [confirmed, setConfirmed] = useState(false);
  const { payload } = payment;

  const handleCopyPaste = useCallback(() => {
    navigator.clipboard.writeText(payload);
    notifications.success(`Chave copiada`);
  }, [payload]);

  useEffect(() => {
    const socket = openSocket();
    if (!socket) return

    socket.on(`pedido-confirm-payment-asaas-${deliveryId}`, (data) => {
      if (data.type === 'update') {
        setConfirmed(data.payload.AsaasConfirmPayment);
        setOrder?.((state) => ({ ...state, ...data.payload }));

        setTimeout(() => onClose(), 3 * 1000);
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <S.Container>
      <S.BackButtonWrapper>
        <BackButton onClick={onClose} />
      </S.BackButtonWrapper>
      {!confirmed ?
        <>
          {amount && (
            <>
              <Text variant="info">Valor a pagar:</Text>
              <Text variant="title">{money(amount)}</Text>
            </>
          )}

          <QRCode value={payload} />
          <S.PixLogo src={pixLogo} />
          <S.DescriptionText>
            Copie o código Pix abaixo e cole para pagar em qualquer aplicativo
            habilitado
          </S.DescriptionText>
          <S.PanelPixCopyPaste>{payload}</S.PanelPixCopyPaste>
          <Button onClick={handleCopyPaste} style={{ color: '#FFFFFF' }}>
            Pix Copia e Cola
          </Button>
        </>
        :
        <S.ConfirmedContainer>
          <S.ConfirmedIcon>
            <FaCheckCircle size={100} />
          </S.ConfirmedIcon>
          <S.ConfirmedDescription>
            Pagamento confirmado com sucesso!
          </S.ConfirmedDescription>
        </S.ConfirmedContainer>}
    </S.Container>
  );
};

export { PaymentModal };
