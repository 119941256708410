import { StatusDelivery } from 'entities/statusDelivery.entity';
import {
  Circle,
  ContainerTimeLine,
  NoRegister,
  SubCard,
  TimeLine,
  TimeLineCard,
  TimeLineTitle,
} from './styles';

interface ITimeLineOrderProps {
  data: StatusDelivery[];
}

const TimeLineOrder: React.FC<ITimeLineOrderProps> = ({ data }) => {
  if (!data?.length) return <NoRegister>Sem registros</NoRegister>;

  return (
    <ContainerTimeLine>
      <TimeLine>
        {data.map((item) => (
          <TimeLineCard>
            <Circle />
            <SubCard>
              <TimeLineTitle>
                {item.Hora} - {item.Status}
              </TimeLineTitle>
            </SubCard>
          </TimeLineCard>
        ))}
      </TimeLine>
    </ContainerTimeLine>
  );
};

export default TimeLineOrder;
