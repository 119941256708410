import React from 'react';
import {
  ObservationContainer,
  ObservationInputDescription,
  ProductComposition,
  Container,
  ProductContainerImage,
  ProductImage,
  ProductName,
  ProductContainer,
  Divider,
  ObservationTitle,
  InsertContainer,
  InsertButtonIncrementContainer,
  InsertButtonShoppingCartContainer,
  InsertButtonText,
  InsertButtonShoppingCart,
  ScreenQuantity,
  ButtonMoreLess,
  UnavailableProduct,
  SizeContainer,
  SizeTitle,
  ProductDescription,
} from './styles';
import { getDay, moeda } from '../../utils/functions';
import { BackButton } from '../../components/ButtonBack';
import Loading from '../../components/Loading';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { parseAndMapSizes } from './utils/parseAndMapSizes';
import FlavorsBorderOptions from './components/Flavors';
import MountingsInsert from './components/MountingsInsert';
import { ListSelectSize } from '../../components/ListSelectSize';
import { Additional } from './components/Additional';
import { useAddProduct } from './useAddProduct';
import api from '../../services/api';
import { ScrollToTopButton } from '../../components/ScrollToTopButton';
import { FallbackImage } from '../../components/FallbackImage';
import { DELIVERY_TYPES } from '../../entities/delivery-types';
import Borders from './components/Borders';
import { useSchedules } from 'hooks/schedules';

const AddProduct = () => {
  const {
    size,
    sizes,
    group,
    config,
    loading,
    borders,
    product,
    flavors,
    quantity,
    mountings,
    flavorsRef,
    bordersRef,
    additionals,
    unavailable,
    observations,
    totalProduct,
    selectedSize,
    mountingsRefs,
    additionalRef,
    checkMountings,
    unavailableMessage,
    selectSize,
    decrement,
    increment,
    addProduct,
    handleBack,
    setFlavors,
    setBorders,
    setMountings,
    setAdditionals,
    setObservations,
    setCheckMountings,
  } = useAddProduct();

  const { schedules } = useSchedules();
  const baseUrl = api.getUri();

  return (
    <>
      {loading && <Loading loading={loading} />}

      {!loading && (
        <Container>
          <ScrollToTopButton bottom={76} right={10} />
          <ProductContainer>
            <ProductContainerImage>
              <BackButton
                onClick={handleBack}
                style={{
                  position: 'absolute',
                  left: 4,
                  top: 4,
                  backgroundColor: 'rgba(17,17,17, 0.5)',
                  zIndex: 100,
                }}
              />
              <ProductDescription>
                <ProductName>{product.Descricao}</ProductName>
                <ProductComposition>{product.Composicao}</ProductComposition>
              </ProductDescription>
              {product.Imagem ? (
                <ProductImage src={`${baseUrl}${product.Imagem}`} />
              ) : (
                <FallbackImage
                  size={parseInt(window.screen.width)}
                  style={{ maxHeight: parseInt(window.screen.height * 0.5) }}
                />
              )}
            </ProductContainerImage>
          </ProductContainer>

          {!!product.IsPizza && !!sizes.length && (
            <SizeContainer>
              <SizeTitle>Selecione o Tamanho</SizeTitle>

              <ListSelectSize
                value={size}
                options={parseAndMapSizes(sizes, product)}
                onSelect={selectSize}
                flavorsRef={flavorsRef}
              />
            </SizeContainer>
          )}
          {!!product.IsPizza &&
            !group?.DesabilitarSabores &&
            flavors.length > 0 && (
              <FlavorsBorderOptions
                containerRef={flavorsRef}
                bordersRef={bordersRef}
                items={flavors}
                setItems={setFlavors}
                size={size}
                sizes={sizes}
                isFlavor
              />
            )}
          {product.TipoProduto === 'PREPARADO' &&
            !product.IsPizza &&
            sizes.length > 0 && (
              <SizeContainer>
                <SizeTitle>Selecione o Tamanho</SizeTitle>
                <ListSelectSize
                  value={size}
                  options={parseAndMapSizes(sizes, product)}
                  onSelect={selectSize}
                />
              </SizeContainer>
            )}
          {!!selectedSize && !!product?.IsPizza && borders.length > 0 && (
            <Borders
              containerRef={bordersRef}
              items={borders}
              setItems={setBorders}
              size={size}
              selectedSize={selectedSize}
              isBorder
              mountingsRefs={mountingsRefs}
              additionalRef={additionalRef}
            />
          )}
          {(sizes.length === 0 || !!selectedSize) && (
            <MountingsInsert
              mountings={mountings}
              setMountings={setMountings}
              mountingsRefs={mountingsRefs}
              checkMountings={checkMountings}
              setCheckMountings={setCheckMountings}
              quantity={quantity}
              additionalRef={additionalRef}
            />
          )}
          {(sizes.length === 0 || !!selectedSize) && additionals.length > 0 && (
            <Additional
              additionals={additionals}
              setAdditionals={setAdditionals}
              additionalRef={additionalRef}
            />
          )}
          <Divider />
          {!config.BloquearObservacao ? (
            <ObservationContainer>
              <ObservationTitle>Observação</ObservationTitle>
              <ObservationInputDescription
                placeholder="Ex: Sem cebola; Carne ao ponto; Sem pimenta; etc"
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
            </ObservationContainer>
          ) : (
            <ObservationContainer>
              <ObservationTitle>{config.MensagemObservacao}</ObservationTitle>
            </ObservationContainer>
          )}
          {!config.TipoEntregaCheck?.includes(DELIVERY_TYPES.view) && (
            <>
              {!unavailable ? (
                <InsertContainer>
                  <InsertButtonIncrementContainer>
                    <ButtonMoreLess onClick={decrement}>
                      <FaMinus size={16} color="#dc3545" />
                    </ButtonMoreLess>
                    <ScreenQuantity>{quantity}</ScreenQuantity>
                    <ButtonMoreLess onClick={increment}>
                      <FaPlus size={16} color="#28a745" />
                    </ButtonMoreLess>
                  </InsertButtonIncrementContainer>
                  <InsertButtonShoppingCartContainer>
                    <InsertButtonShoppingCart
                      color={config.Tema}
                      onClick={addProduct}
                      disabled={!(totalProduct > 0) || !(getDay(schedules) && config?.IsOpen)}
                      aria-label={!(getDay(schedules) && config?.IsOpen) ? 'Loja fechada' : ''}
                    >
                      {getDay(schedules) && config.IsOpen ? (
                        <>
                          <InsertButtonText>Adicionar</InsertButtonText>
                          <InsertButtonText>
                            {totalProduct ? moeda(totalProduct) : null}
                          </InsertButtonText>
                        </>
                      ) : (
                        <InsertButtonText>Loja fechada</InsertButtonText>
                      )}
                    </InsertButtonShoppingCart>
                  </InsertButtonShoppingCartContainer>
                </InsertContainer>
              ) : (
                <UnavailableProduct>{unavailableMessage}</UnavailableProduct>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};
export default AddProduct;
