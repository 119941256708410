import React from 'react';
import { useConfig } from '../../hooks/config';
import { useCompany } from '../../hooks/useCompany';
import { getDay, moeda } from '../../utils/functions';
import {
  AvailabilityContainer,
  Container,
  FreeShipping,
  FreeShippingContainer,
  HeaderDescription,
  HeaderImage,
  HeaderItem,
  HeaderTitle,
} from './styles';
import { useSchedules } from '../../hooks/schedules';
import api from '../../services/api';
import { FallbackImage } from '../FallbackImage';

const Header = () => {
  const { config } = useConfig();
  const { company } = useCompany();
  const { schedules } = useSchedules();
  document.title = company ? company.NomeFantasia : 'App Delivery';

  const baseUrl = api.getUri();

  const backgroundImage =
    config?.Background && config?.UseHomeBackground
      ? config.Background.startsWith('data:image/')
        ? config.Background
        : `${baseUrl}${config.Background}`
      : null;

  const logomarca = config.Logomarca
    ? config.Logomarca.startsWith('data:image/')
      ? config.Logomarca
      : `${baseUrl}${config.Logomarca}`
    : null; // no_img;

  return (
    <Container color={config.Tema} backgroundImage={backgroundImage}>
      {company && (
        <>
          {logomarca ? (
            <HeaderImage src={logomarca} />
          ) : (
            <FallbackImage size={100} style={{ margin: 15 }} />
          )}
          <HeaderItem color={config.Tema}>
            <HeaderTitle>{company.NomeFantasia}</HeaderTitle>

            {!!config.MostrarEndereco && (
              <HeaderDescription>
                {company.Logradouro}, {company.Numero} {company.Bairro},{' '}
                {company.Cidade} - {company.Estado}, {company.Cep}
              </HeaderDescription>
            )}

            {getDay(schedules) && config.IsOpen ? (
              <AvailabilityContainer color="#008000">
                Aberto
              </AvailabilityContainer>
            ) : (
              <AvailabilityContainer color="#dc3545">
                Fechado
              </AvailabilityContainer>
            )}

            {config.FreteGratis && config.FreteGratis > 0 && (
              <FreeShippingContainer>
                <FreeShipping>
                  Frete grátis acima de {moeda(config.FreteGratis)}
                </FreeShipping>
              </FreeShippingContainer>
            )}
          </HeaderItem>
        </>
      )}
    </Container>
  );
};
export default Header;
