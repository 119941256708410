import { useEffect } from 'react';
import {
  ConsultOrder,
  Container,
  NoProductContainer,
  NotProductDescription,
  NotProductsTitle,
  ShoppingCartContainer,
  ShoppingCartList,
  Header,
  Content,
} from './styles';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { useCart } from '../../hooks/useCart';
import { useTab } from '../../hooks/tabIndex';
import { CartItem } from './components/CartItem';
import { BackButton } from '../../components/ButtonBack';
import { useTheme } from 'styled-components';
import { FBPixelTrackEvent } from '../../events/FaceBookPixelEvents';
import { CartFooter } from './components/CartFooter';
import { Spacer } from '../../components/Spacer';
import { useConfig } from 'hooks/config';
import { useAuth } from 'hooks/auth';
import { notification } from 'utils/notifications';

const ShoppingCart = () => {
  const { cart, total } = useCart();
  const { config } = useConfig();
  const { isAuth } = useAuth()
  const { setTab, handleFinish, finish } = useTab();
  const { colors } = useTheme();

  const onSubmit = () => {
    if (!isAuth && config.SellOnlyWithLogin) {
      notification('warn', 'Não é permitido enviar o pedido sem usuário cadastrado!');
      setTimeout(() => setTab(4), 2500)
    } else {
      handleFinish(true);
    }
  };

  useEffect(() => {
    if (finish) {
      FBPixelTrackEvent.initiateCheckout({
        currency: 'BRL',
        value: total,
      });
    }
  }, [finish]);

  return (
    <Container>
      <Header>
        <BackButton
          onClick={() => setTab(1)}
          style={{
            marginBottom: 0,
            padding: '6px',
            backgroundColor: 'rgba(17,17,17, 0.5)',
          }}
        />
      </Header>
      <Content>
        {cart?.length > 0 && (
          <ShoppingCartContainer>
            <ShoppingCartList>
              {cart.map((item, index) => (
                <CartItem
                  key={`item-cart-${index}`}
                  item={item}
                  index={index}
                />
              ))}
            </ShoppingCartList>

            <Spacer flex={1} />

            <CartFooter onSubmit={onSubmit} total={total} />
          </ShoppingCartContainer>
        )}
        {cart?.length === 0 && (
          <NoProductContainer>
            <MdOutlineShoppingCart size={70} color={colors.main} />
            <NotProductsTitle>Carrinho Vazio</NotProductsTitle>
            <NotProductDescription>
              Nenhum produto foi adicionado ao carrinho. Adicione produtos no
              carrinho para fazer o pedidio
            </NotProductDescription>

            <ConsultOrder onClick={() => setTab(7)}>
              Consultar Pedido
            </ConsultOrder>
          </NoProductContainer>
        )}
      </Content>
    </Container>
  );
};

export default ShoppingCart;
