import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import DetailsOrder from '../../../components/DetailsOrder';
import ListDeliveries from '../../../components/ListDeliveries';
import Loading from '../../../components/Loading';
import Title from '../../../components/Title';
import { ButtonReload, Container, ContainerButtonReload } from './styles';
import { useAuth } from '../../../hooks/auth';
import { getOrder } from '../../../httpRequests/order/getOrder';
import { useOrdersSocket } from '../../../hooks/useOrdersSocket';
import { useDeliveries } from './useDeliveries';
import { Order } from '../../../entities/Order.entity';

const Delivery = ({
  visible,
  orderId,
}: {
  visible: boolean;
  orderId?: string;
}) => {
  const { user } = useAuth();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const deliveries = useDeliveries({ user });
  useOrdersSocket({
    onUpdate: deliveries.updateDeliveries,
    userId: user?.Id,
  });

  const showOrder = (order: Order | null) => {
    setSelectedOrder(order);
  };

  const loadOrder = async (id: string) => {
    await getOrder(id);
  };

  const onCloseOrder = () => {
    setSelectedOrder(null);
  };

  useEffect(() => {
    if (orderId) {
      loadOrder(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    deliveries.updateDeliveries();
  }, [deliveries.updateDeliveries, selectedOrder]);

  return (
    <>
      {visible && (
        <Container>
          <Title>Meus Pedidos</Title>
          <ContainerButtonReload>
            <ButtonReload onClick={() => deliveries.updateDeliveries()}>
              <MdRefresh size={20} />
            </ButtonReload>
          </ContainerButtonReload>
          {deliveries.loading ? (
            <Loading loading={true} />
          ) : (
            <ListDeliveries
              list={deliveries.deliveries}
              showOrder={showOrder}
              page={deliveries.deliveriesPage}
              handlePrev={deliveries.handlePrev}
              handleNext={deliveries.handleNext}
            />
          )}
          {selectedOrder && (
            <DetailsOrder
              order={selectedOrder}
              setOrder={setSelectedOrder}
              onClose={onCloseOrder}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default Delivery;
