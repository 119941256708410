import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%; 
  border-bottom: 1px solid #b3b3b3;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerInputSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
`;

export const InputSearch = styled.input`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  min-height: 30px;
  margin-left: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  color: ${({ theme }) => theme.colors.text};
`;

export const ContainerInfoProducts = styled.div`
  align-items: center;
`;

export const DescriptionInfoProducts = styled.div`
  display: flex;
  margin: 16px;
  font-weight: bold;
  color:  ${({ theme }) => theme.colors.title}
`;
