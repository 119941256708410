import api from '../../services/api';

export async function getPaymentByOrderId(
  orderId: string,
  companyId: string | number,
): Promise<any | null> {
  try {
    const { data } = await api.get(`/asaas/pedido/${companyId}/${orderId}`);
    return data !== '' ? data : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getConfirmPayment(
  orderId: string,
): Promise<any | null> {
  try {
    const { data } = await api.get(`/pedido/confirmarPagamento/${orderId}`);
    return data !== '' ? data : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}