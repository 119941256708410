import { useCallback, useEffect, useRef, useState } from "react";
import { useCatalog } from "../../hooks/useCatalog";
import { useProducts } from "../../hooks/useProducts";
import { useCompany } from "../../hooks/useCompany";
import {
  Company, Container, ContainerGroupProducts, ContainerGroups,
  ContainerOptions, GroupProducts, GroupTitle, ItemGroup, ListGroups,
} from "./styles";
import Loading from '../../components/Loading';
import ListProducts from "../../components/ListProducts";
import { Link } from "react-scroll";
import { FloatCartButton } from "../../components/FloatCartButton";
import { InitializeFaceBookPixel } from '../../events/FaceBookPixelEvents';

const CatalogNew = () => {
  const { company } = useCompany();
  const [groupSelected, setGroupSelected] = useState(null);
  const { products } = useProducts();
  const [showOptions, setShowOptions] = useState(false);
  const [withGroup, setWithGroup] = useState([])
  const triggerHeight = 135;
  const { groups, loading } = useCatalog();
  InitializeFaceBookPixel();
  const containerRef = useRef(null);

  const handleGroup = useCallback((index, group) => {
    const container = containerRef.current;
    if (container) {
      const item = container.children[index];
      if (item) {
        const containerWidth = container.offsetWidth;
        const itemOffsetLeft = item.offsetLeft - container.offsetLeft;
        const itemWidth = item.offsetWidth;
        container.scrollLeft = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
      }
    }
    setGroupSelected(group);
  }, []);

  const renderListGroups = () => {
    return (
      <ContainerGroups>
        <Company active={showOptions}>{company.NomeFantasia}</Company>
        <ListGroups ref={containerRef} className="list-group-horizontal">
          {groups.map((gr, index) =>
            <ItemGroup
              className="group-item"
              key={gr.Id}
              active={gr.Id === groupSelected?.Id}
              onClick={() => handleGroup(index, gr)}
            >
              <Link
                to={gr.Id}
                spy={true}
                smooth={true}
                offset={-80}
                onClick={() => handleGroup(index, gr)}
              >
                {gr.Descricao}
              </Link>
            </ItemGroup>,
          )}
        </ListGroups>
      </ContainerGroups>
    )
  }

  const renderOptions = () => {
    if (showOptions) {
      return (
        <ContainerOptions show={showOptions}>
          {renderListGroups()}
        </ContainerOptions>
      );
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setShowOptions(window.scrollY > triggerHeight)
    });
  }, [])

  useEffect(() => {
    let widths = [];
    groups.forEach((group) => {
      const div = document.getElementById(group.Id);
      const posicao = div?.getBoundingClientRect();
      widths.push({ width: posicao?.top, ...group });
    })
    setWithGroup(widths);
  }, [containerRef.current])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (withGroup) {
        withGroup.forEach((item, index) => {
          if (window.scrollY >= item?.width && window.scrollY <= withGroup[index + 1]?.width) {
            handleGroup(index, item);
          } else if (
            index === withGroup.length - 1 &&
            window.scrollY >= withGroup[withGroup.length - 1]?.width) {
            handleGroup(index, withGroup[withGroup.length - 1]);
          }
        })
      }
    });
  }, [withGroup])

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Container>
      {renderListGroups()}
      {renderOptions()}
      <ContainerGroupProducts>
        {groups.map((gr) =>
          <GroupProducts key={gr.Id} id={gr.Id}>
            <GroupTitle>{gr.Descricao}</GroupTitle>
            <ListProducts
              products={products.filter(x => x.Grupo === gr.Id)}
              searchProduct={''}
              group={gr}
            />
          </GroupProducts>,
        )}
      </ContainerGroupProducts>
      <FloatCartButton />
    </Container>
  )
}

export default CatalogNew;