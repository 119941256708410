import styled, { css } from 'styled-components';
import { Element } from "react-scroll";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  color: ${({ theme }) => theme.colors.title};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerGroups = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  background: #f3f3f3;
`

export const Company = styled.div`
  display: none;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.main};
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  ${props => props.active ?
    css`
      display: block;
    `
    : ''}
`

export const ListGroups = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  overflow-x: auto;
  scroll-behavior: smooth;
`

export const ItemGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #444343;

  ${props => props.active ?
    css`
      border-bottom: 2px solid;
      color: ${({ theme }) => theme.colors.main};
    `
    : ''}
`

export const ContainerGroupProducts = styled.div`
  margin-bottom: 48px;
`

export const GroupProducts = styled(Element)`
  margin: 15px 0px;
`

export const GroupTitle = styled.div`
  padding: 5px 20px;
  margin: 5px 0px;
  background: ${({ theme }) => theme.colors.main}20;
  font-size: 18px;
  font-weight: 700;
  border-top: 1px solid ${({ theme }) => theme.colors.main}40;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main}40;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`

export const ContainerOptions = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  
  ${props => props.show ?
    css` height: 50px;`
    : css` height: 0px; `}

`