import { useCallback, useEffect, useState } from 'react';
import { useGroup } from './group';
import { getGroups } from '../httpRequests/group/getGroups';
import { IdEmpresa } from '../services/api';
import { getPromotionProducts } from '../httpRequests/products/getPromotionProducts';
import { filterGroupsBySchedules } from '../utils/handlers/filterGroupsBySchedules';

export const useCatalog = () => {
  const [groups, setGroups] = useState([]);
  const { setGroup } = useGroup();
  const [loading, setLoading] = useState(true);
  const [promotionProducts, setPromotionProducts] = useState([]);

  const listGroups = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getGroups(IdEmpresa);
      const filteredData = await filterGroupsBySchedules(data, IdEmpresa);
      setGroups(filteredData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const listPromotionProducts = useCallback(async () => {
    try {
      const data = await getPromotionProducts(IdEmpresa);

      setPromotionProducts(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeGroup = (group) => () => {
    setGroup(group);
  };

  useEffect(() => {
    listGroups();
    listPromotionProducts();
  }, [listGroups, listPromotionProducts]);

  return {
    groups,
    changeGroup,
    loading,
    promotionProducts,
  };
};
