import { useEffect, useState } from 'react';
import { MdCalendarToday } from 'react-icons/md';
import { BsClockHistory } from 'react-icons/bs';
import { formatDate, moeda } from '../../utils/functions';
import { BackButton } from '../ButtonBack';
import Title from '../Title';
import * as S from './styles';
import Loading from '../Loading';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { Spacer } from '../Spacer';
import { PaymentModal } from '../PaymentModal/PaymentModal';
import { getPaymentByOrderId } from 'httpRequests/payment/getPaymentByOrderId';
import { Product } from './Product/Product';
import { defatultStatusState } from './constants';
import { getOrderProduct } from 'httpRequests/order/getOrderProduct';
import { getAddress, getStatus } from './utils';
import { format } from 'date-fns';
import { useConfig } from '../../hooks/config';
import { getMinutesFromTime } from 'utils/handlers/getMinutesFromTime';
import { Order } from 'entities/Order.entity';
import { PixPayment } from 'entities/pixPayment.entity';
import { StatusDelivery } from 'entities/statusDelivery.entity';
import { getOrderStatus } from 'httpRequests/order/getOrderStatus';
import TimeLineOrder from 'components/TimeLineOrder';

type DetailsOrderProps = {
  order: Order;
  setOrder?: React.Dispatch<React.SetStateAction<Order | null>> | undefined | null;
  onClose: () => void;
};
const DetailsOrder = ({ order, setOrder, onClose }: DetailsOrderProps) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [status, setStatus] =
    useState<Partial<typeof defatultStatusState>>(defatultStatusState);
  const { colors } = useTheme();
  const { config } = useConfig();
  const [openPayment, setOpenPayment] = useState(false);
  const [payment, setPayment] = useState<PixPayment | null>(null);
  const address = getAddress(order.Id);
  const [statusdelivery, setStatusDelivery] = useState<StatusDelivery[]>([]);

  const getPayment = async () => {
    const data = await getPaymentByOrderId(order.Id, order.IdEmpresa);
    if (data) setPayment(data);
  };

  const update = async () => {
    setLoading(true);

    const data = await getOrderProduct(order.Id);

    if (data) setProducts(data);

    const orderStatus = await getOrderStatus(order.Codigo);
    if (status) setStatusDelivery(orderStatus);

    setLoading(false);
  };

  const updateStatus = () => {
    setStatus(getStatus(order.Status));
  };

  const handlePay = () => {
    setOpenPayment((state) => !state);
  };

  useEffect(() => {
    getPayment();
    update();
  }, []);

  useEffect(() => {
    updateStatus();
  }, [order]);

  return (
    <S.Container>
      <BackButton onClick={onClose} />
      <S.ContainerOrder>
        <Title>Pedido Nº {order.Codigo}</Title>
        <S.SubTitleOrder>
          {`Em ${formatDate(order.DataPedido)} às ${order.Hora}`}{' '}
        </S.SubTitleOrder>
      </S.ContainerOrder>
      {order.AsaasPaymentId && (
        <S.ContainerPayment>
          <S.ConfirmPayment confirm={order.AsaasConfirmPayment}>
            {order.AsaasConfirmPayment ? 'Pago Online' : 'Aguardando Pagamento'}
          </S.ConfirmPayment>
        </S.ContainerPayment>
      )}
      <S.StatusOrderContainer>
        <Title>Status</Title>
        <TimeLineOrder data={statusdelivery} />
      </S.StatusOrderContainer>
      <S.ContainerInfoOrder>
        <S.TitleDescription>Forma de pagamento</S.TitleDescription>
        <S.PaymentOrder>
          {+order.FormaPag === 99 ? 'PIX' : order.FormaPagamento}
        </S.PaymentOrder>
        <S.TitleDescription>Forma de entrega</S.TitleDescription>
        <S.TipeOrder>
          {['EN', 'AG'].includes(order.TipoEntrega) && (
            <>{order.TipoEntrega === 'EN' ? 'Entrega' : 'Entrega Agendada'}</>
          )}
          {['RE', 'AGRE'].includes(order.TipoEntrega) && (
            <>{order.TipoEntrega === 'RE' ? 'Retirada' : 'Retirada Agendada'}</>
          )}
          {order.TipoEntrega === 'LOCAL' && <>Consumir no local</>}
        </S.TipeOrder>

        {order.TipoEntrega?.includes('AG') && (
          <S.TipeOrder>
            <>
              <MdCalendarToday color={colors.main} size={25} />
              {order.TipoEntrega === 'AG' ? 'Entregar em ' : 'Retiradar em '}
              {format(
                new Date(order.DataAgendamento.toString().replace('.000Z', '')),
                'dd/MM/yyyy HH:mm',
              )}
            </>
          </S.TipeOrder>
        )}
      </S.ContainerInfoOrder>

      {Boolean(
        config.MinTimePickUp &&
        config.MaxTimePickUp &&
        config.MinTimeDelivery &&
        config.MaxTimeDelivery,
      ) && (
          <S.StatusOrderContainer>
            <Title>
              Tempo para {['LOCAL'].includes(order.TipoEntrega) && 'conclusão'}
              {['EN', 'AG'].includes(order.TipoEntrega) && 'entrega'}
              {['RE', 'AGRE'].includes(order.TipoEntrega) && 'retirada'}
            </Title>
            <S.DataOrder>
              <BsClockHistory color={colors.main} size={25} />
              Tempo medio para{' '}
              {order.TipoEntrega === 'LOCAL'
                ? `conclusão entre ${getMinutesFromTime(
                  config.MinTimePickUp,
                )} e ${getMinutesFromTime(config.MaxTimePickUp)} minutos`
                : order.TipoEntrega?.includes('RE')
                  ? `retirada entre ${getMinutesFromTime(
                    config.MinTimePickUp,
                  )} e ${getMinutesFromTime(config.MaxTimePickUp)} minutos`
                  : `entrega entre ${getMinutesFromTime(
                    config.MinTimeDelivery,
                  )} e ${getMinutesFromTime(config.MaxTimeDelivery)} minutos`}
            </S.DataOrder>
          </S.StatusOrderContainer>
        )}

      {address && (
        <S.ContainerAddress>
          <S.TitleDescription style={{ fontWeight: 600 }}>
            Endereço
          </S.TitleDescription>
          <S.Address>{address}</S.Address>
        </S.ContainerAddress>
      )}
      {loading && <Loading loading={loading} />}
      {!loading && (
        <>
          <S.TitleDescription margin>Produtos</S.TitleDescription>
          <S.ContainerProducts>
            {products.map((product: any) => (
              <Product
                key={`${product.IdProduto}-${order.Id}`}
                product={product}
                order={order}
              />
            ))}
          </S.ContainerProducts>

          <S.ContainerTotal>
            <S.Total>
              <S.ContainerSubtotal>
                <S.SubTotalOrder>Subtotal</S.SubTotalOrder>
                <S.SubTotalOrder>{moeda(order.ValorTotal)}</S.SubTotalOrder>
              </S.ContainerSubtotal>
              {order.Desconto > 0 && (
                <S.ContainerSubtotal>
                  <S.SubTotalOrder>Desconto</S.SubTotalOrder>
                  <S.SubTotalOrder>{moeda(order.Desconto)}</S.SubTotalOrder>
                </S.ContainerSubtotal>
              )}
              <S.ContainerSubtotal>
                <S.TotalDelivery>Taxa Entrega</S.TotalDelivery>
                <S.TotalDelivery>{moeda(order.TaxaEntrega)}</S.TotalDelivery>
              </S.ContainerSubtotal>
              <S.ContainerSubtotal>
                <S.TotalOrder>Total</S.TotalOrder>
                <S.TotalOrder>
                  {moeda(
                    Number(order.ValorTotal) +
                    Number(order.TaxaEntrega ?? 0) -
                    Number(order.Desconto ?? 0),
                  )}
                </S.TotalOrder>
              </S.ContainerSubtotal>
            </S.Total>
          </S.ContainerTotal>

          <Spacer flex={1} />

          {payment && !order.AsaasConfirmPayment && (
            <div style={{ padding: 20 }}>
              <Button style={{ color: '#ffffff' }} onClick={handlePay}>
                Pagar
              </Button>
            </div>
          )}
        </>
      )}

      {openPayment && payment && (
        <PaymentModal
          payment={payment}
          onClose={handlePay}
          deliveryId={order.Id}
          setOrder={setOrder}
        />
      )}
    </S.Container>
  );
};

export default DetailsOrder;
