/* eslint-disable */
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { notification } from '../utils/notifications';

const AuthContext = createContext({});
const TOKEN_KEY = '@app-delivery-Token';
const USER = '@app-delivery-User';

const initialState = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const user = localStorage.getItem(USER);

  if (token && user) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const usr = JSON.parse(atob(user));
    return {
      token,
      user: usr,
      id_empresa: usr.IdEmpresa,
    };
  }

  return {};
};

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(initialState());

  const signIn = useCallback(async (login, password) => {
    try {
      const response = await api.post('/auth/usuario', {
        Telefone: login,
        Senha: btoa(password),
      });

      const { access_token, user } = response.data;
      localStorage.setItem(TOKEN_KEY, access_token);
      localStorage.setItem(USER, btoa(JSON.stringify(user)));
      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      setData({
        token: access_token,
        user: user,
      });
      return { signed: true, user: user };
    } catch (error) {
      console.log(error, error?.reponse?.data);
      notification('error', 'Usuário ou senha incorretos');
      return { signed: false };
    }
  }, []);

  const signUp = useCallback(
    async ({ companyId, name, phone, email, password, document }) => {
      try {
        await api.post('usuario/criar', {
          IdEmpresa: companyId,
          Nome: name,
          Telefone: phone,
          Document: document,
          Email: email,
          Senha: password,
        });

        notification('success', 'Cadastrado com sucesso');

        return true;
      } catch (error) {
        const errorMessage = error?.response?.data?.message;

        console.log(error, error?.reponse?.data);

        if (errorMessage) {
          notification('error', errorMessage);
        }
        return null;
      }
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER);

    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signUp,
        signOut,
        isAuth: !!data?.user?.Id,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const { user, signIn, signUp, signOut, isAuth } = useContext(AuthContext);

  return { user, signIn, signUp, signOut, isAuth };
}

export { AuthProvider, useAuth };
