import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const BackButtonWrapper = styled.div`
  align-self: start;
`;

export const DescriptionText = styled.div`
  font-size: 11px;
`

export const PanelPixCopyPaste = styled.div`
  overflow-wrap: break-word;
  width: 100%;
  background-color: #b8bcbf;
  padding: 10px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px;
  margin: 20px;
`

export const PixLogo = styled.img`
  width: 100px;
  margin: 20px;
`;

export const ConfirmedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #F2F2F2;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`

export const ConfirmedIcon = styled.div`
  color: #47E368;
`

export const ConfirmedDescription = styled.div`
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 6px;
`