import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const FinishContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 560px;
  padding: 24px 20px;
  overflow: auto;
`;

export const FinishTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.title};
`;

export const FinishFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
`;

export const FinishType = styled.div`
  font-weight: 500;
`;

export const FinishFormTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
  margin: 0px;
`;

export const FinishChange = styled.div``;

export const FinishCoupom = styled.div``;

export const FinishCoupomButton = styled.div`
  margin: 10px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.shapeGray};
  color: ${({ theme }) => theme.colors.text};

  &:active {
    background: ${({ theme }) => shade(0.4, theme.colors.shapeGray)};
  }
`;

export const FinishPaymentsAccountContainer = styled.div``;

export const FinishPaymentAccount = styled.div`
  background: #fff;
  padding: 10px;
  margin: 4px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #e9e8e8;
`;

export const PaymentAccountBank = styled.div`
  font-weight: 600;
  border-bottom: 1px solid #bcbcbc;
`;

export const PaymentAccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleProof = styled.div`
  font-size: 11px;
  margin-left: 10px;
`;

export const PaymentAccountData = styled.div``;

export const FinishTotalsContainer = styled.div`
  margin: 0px 10px;
  border-top: 1px solid #e9e8e8;
  border-bottom: 1px solid #e9e8e8;
`;

export const FinishTaxeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TaxeData = styled.div`
  font-size: 1rem;
  color: ${({ theme, successColor }) =>
    successColor ? theme.colors.success : theme.colors.text};
`;

export const FinishTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalData = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
`;

export const FinishButton = styled.button`
  display: flex;
  font-family: 'Poppins';
  justify-content: center;
  border: 0;
  align-items: center;
  background: ${({ theme, mainColor }) =>
    mainColor ? theme.colors.main : theme.colors.backgroundSecondary};
  padding: 3px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  width: 130px;
  height: 40px;
  color: ${({ theme, mainColor }) =>
    mainColor ? theme.colors.shape : theme.colors.text};
  cursor: pointer;

  :disabled {
    opacity: 0.5;

    &:active {
      background: ${({ theme, mainColor }) =>
    mainColor ? theme.colors.main : theme.colors.backgroundSecondary};
    }
  }

  &:active {
    background: ${({ theme, mainColor }) =>
    shade(
      0.4,
      mainColor ? theme.colors.main : theme.colors.backgroundSecondary,
    )};
  }
`;

export const NoDeliveryTypes = styled.div`
  padding: 0 16px;
  display: flex;
  gap: 4px;
`;

export const StepsContainer = styled.div``;

export const ButtonAddAddressContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`